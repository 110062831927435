import React from 'react';

const CompetitorMarker = ({ color, size = 30 }) => {
  const width = size;
  const height = (4 / 3) * size;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 40">
      <path fill={color} d="M15 0C9.5 0 5 4.5 5 10c0 7.5 10 20 10 20s10-12.5 10-20C25 4.5 20.5 0 15 0z" />
      <circle cx="15" cy="10" r="6" fill="#ffffff" />
    </svg>
  );
};

export default CompetitorMarker;
